var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-6" },
    [_c("change-password", { attrs: { token: _vm.token } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }