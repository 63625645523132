<template>
  <div class="col-6">
    <change-password :token="token" />
  </div>
</template>

<script>
import ChangePassword from './ChangePassword'

export default {
  name: 'ResetPassword',
  components: { ChangePassword },
  data() {
    return {
      token: null,
    }
  },
  async mounted() {
    if (!this.$route.params.token) {
      return
    }

    this.token = this.$route.params.token
  },
}
</script>
